import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Inner from "../views/Inner.vue";
import Outer from "../views/Outer.vue";
import OldArchive from "../views/OldArchive.vue";
import Inside from "../views/Inside.vue";
import Subjects from "../views/Subjects.vue";
import Settings from "../views/Settings.vue";
import Login from "../views/Login.vue";
import Employee from "../views/Employee.vue";
import EmployeeDetails from "../views/EmployeeDetails.vue";
import SectionNotification from "../views/SectionNotification.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/old",
    name: "OldArchive",
    component: OldArchive,
  },

  {
    path: "/notify-section",
    name: "SectionNotification",
    component: SectionNotification,
  },
  {
    path: "/inner",
    name: "Inner",
    component: Inner,
  },
  {
    path: "/employee-details",
    name: "EmployeeDetails",
    component: EmployeeDetails,
  },
  {
    path: "/employee",
    name: "Employee",
    component: Employee,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/outer",
    name: "Outer",
    component: Outer,
  },
  {
    path: "/inside",
    name: "Inside",
    component: Inside,
  },
  {
    path: "/subjects",
    name: "Subjects",
    component: Subjects,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
