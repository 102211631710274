<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container" fluid>
      <v-card color="accent" elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3 class="warning-color">اشعارات الاقسام</h3>
          </v-col>

          <v-col cols="2" style="text-align: left">
            <AddSectionNotification @reloadTask="initialData" />
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="container" fluid>
      <v-card color="accent" elevation="6">
        <v-card-title class="warning-color">
          الاشعارات
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="بحث" single-line hide-details solo></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
          <template v-slot:[`item.isActive`]="{ item }">
            <v-avatar size="20" color="success" v-if="item.isActive == 1"> </v-avatar>

            <v-avatar size="20" color="error" v-else> </v-avatar>
          </template>

          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="dialog = false" v-if="$store.state.userData.roleId != 31">
              <DeleteSectionNotification :dataToDelete="item" @reloadTask="initialData()" />
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import DeleteSectionNotification from "../components/sectionNotification/DeleteSectionNotification.vue";
import AddSectionNotification from "../components/sectionNotification/AddSectionNotification.vue";

export default {
  components: {
    DeleteSectionNotification,
    AddSectionNotification,
  },
  data: () => ({
    items: [],
    search: "",
    overlay: false,
    headers: [
      { text: "العدد", value: "idSectionNotification" },
      { text: "العنوان", value: "title" },
      { text: "تاريخ الاشعار", value: "createdAtFormatted" },
      {
        text: "القسم",
        sortable: false,
        value: "sectionName",
      },
      {
        text: "نص الاشعار",
        sortable: false,
        value: "description",
      },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.overlay = true;
      self.$http
        .get(`${self.$store.state.apiUrlEmployee}/sectionNotifications`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.items = res.data;
          self.overlay = false;
        });
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.items.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.title.toLowerCase().includes(v));
        });
      } else {
        return this.items;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
