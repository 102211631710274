<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container" fluid>
      <v-card color="accent" elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="9">
            <h3 class="warning-color">صفحة المذكرات الداخلية</h3>
          </v-col>

          <v-col cols="3" style="text-align: left">
            <AddInside @reloadTask="initialData()" style="display: inline-block" />

            <SearchInside style="display: inline-block" @clicked="onSearch" />

            <v-menu rounded="lg" offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn class="mx-2" fab small color="primary" v-bind="attrs" v-on="on">
                  <v-icon dark> mdi-sort-bool-descending-variant </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in sortedBy" :key="index" link @click="sortingData(item)">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="container" fluid>
      <v-card color="accent" elevation="6">
        <v-card-title class="warning-color">
          المذكرات الداخلية
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="بحث" single-line hide-details outlined></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
          <template v-slot:[`item.isActive`]="{ item }">
            <v-avatar size="20" color="success" v-if="item.isActive == 1"> </v-avatar>

            <v-avatar size="20" color="error" v-else> </v-avatar>
          </template>

          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <v-avatar size="32" v-if="item.image != null">
              <img :src="item.image" alt="John" />
            </v-avatar>

            <v-avatar size="32" color="indigo" v-else>
              <v-icon dark> mdi-account-circle </v-icon>
            </v-avatar>
          </template>

          <template v-slot:[`item.isSeen`]="{ item }">
            <v-icon color="success" v-if="item.isRead == 1"> mdi-eye </v-icon>
            <v-icon color="error" v-if="item.isRead == 0"> mdi-eye-off </v-icon>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <EditInside v-if="$store.state.userData.sectionId == item.from.idSection && item.isRead == 0" style="display: inline-block" :dataToEdit="item" @reloadTask="initialData()" />

            <!-- زيد خلف ابراهيم هاشم / f122 -->

            <!-- <v-btn icon @click="dialog = false">
                     <DeleteInside
                        :dataToDelete="item"
                        @reloadTask="initialData()"
                     />
                  </v-btn> -->
            <ShowDetails ref="showDetails" :images="item.images" style="display: inline-block"></ShowDetails>

            <v-icon @click="showComponent(item)" color="primary"> mdi-eye </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AddInside from "../components/inside/AddInside.vue";
import EditInside from "../components/inside/EditInside.vue";
import SearchInside from "../components/inside/SearchInside.vue";
import DeleteInside from "../components/inside/DeleteInside.vue";
import ShowDetails from "../components/inside/ShowDetails.vue";
import Images from "../components/images.vue";
export default {
  components: {
    AddInside,
    SearchInside,
    Images,
    EditInside,
    ShowDetails,
    DeleteInside,
  },
  data: () => ({
    sortedBy: [
      { id: 1, name: "المذكرات الواردة" },
      { id: 2, name: "المذكرات الصادرة" },
      { id: 3, name: "كل المذكرات" },
    ],
    items: [],
    search: "",
    overlay: false,
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "تاريخ انشاء الكتاب", value: "createdAtFormatted" },
      { text: "من", value: "from.sectionName" },
      { text: "الى", value: "to.sectionName" },
      { text: "تمت معاينته", value: "isSeen" },
      // { text: "رقم الكتاب", value: "archiveNumber" },
      // {
      //    text: "الموضوع",
      //    align: "start",
      //    value: "subjectName",
      // },
      // {
      //    text: "نص الكتاب",
      //    value: "subjectDescription",
      // },
      // {
      //    text: "الملاحظات",
      //    value: "note",
      // },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    onSearch(value) {
      console.log(value);
      this.items = value;
    },
    showComponent(document) {
      if (this.$store.state.userData.sectionId == document.from.idSection) {
        this.$refs.showDetails.showDialogDetails(document);
      } else {
        this.$http
          .put(`${this.$store.state.apiUrl}/archiveRead/${document.idArchive}`, {
            isRead: true,
          })
          .then((res) => {
            console.log(res);
            this.initialData();
            this.$refs.showDetails.showDialogDetails(document);
          });
      }
    },
    sortingData(item) {
      console.log(item);
      if (item.id == 1) {
        this.items = this.$store.state.insideOriginalArray.filter((item) => item.to.idSection == this.$store.state.userData.sectionId);
      } else if (item.id == 2) {
        this.items = this.$store.state.insideOriginalArray.filter((item) => item.from.idSection == this.$store.state.userData.sectionId);
        console.log(this.items);
      } else {
        this.items = this.$store.state.insideOriginalArray;
      }
    },
    initialData() {
      let self = this;
      self.overlay = true;
      self.$http
        .get(`${self.$store.state.apiUrl}/searchArchive?archiveTypeId=3&sectionId=${self.$store.state.userData.sectionId}&yearStudyId=73`)
        .then((res) => {
          console.log(res.data);

          self.items = res.data.map((item) => {
            let sectionNameField = self.$store.state.sections.find((section) => section.idSection == item.from);
            console.log(sectionNameField);

            return {
              archiveDate: item.archiveDate,
              archiveDateFormatted: item.archiveDateFormatted,
              archiveNumber: item.archiveNumber,
              archiveSubjectId: item.archiveSubjectId,
              archiveTypeId: item.archiveTypeId,
              createdAt: item.createdAt,
              createdAtFormatted: item.createdAtFormatted,
              from: self.$store.state.sections.find((section) => section.idSection == item.from),
              idArchive: item.idArchive,
              idArchiveSubject: item.idArchive,
              idArchiveType: item.idArchiveType,
              images: item.images,
              incomeDate: item.incomeDate,
              incomeDateFormatted: item.incomeDateFormatted,
              incomeNumber: item.incomeNumber,
              note: item.note,
              sectionId: item.sectionId,
              subjectDescription: item.subjectDescription,
              subjectName: item.subjectName,
              to: self.$store.state.sections.find((section) => section.idSection == item.to),
              typeName: item.typeName,
              isRead: item.isRead,
              yearStudyId: item.yearStudyId,
            };
          });

          console.log(self.items);
          self.$store.state.insideOriginalArray = self.items;
          self.overlay = false;
        })
        .catch((err) => {
          self.overlay = false;
        });
    },
  },
  computed: {
    ItemsFilter: {
      get: function () {
        if (this.search) {
          return this.items.filter((item) => {
            return this.search
              .toLowerCase()
              .split(" ")
              .every((v) => item.subjectDescription.toLowerCase().includes(v));
          });
        } else {
          return this.items;
        }
      },
      set: (items) => {
        return items;
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
