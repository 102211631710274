<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة امر اداري</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-autocomplete v-model="archiveSubject" :items="$store.state.subjects" item-text="subjectName" item-value="idArchiveSubject" outlined rounded label="الموضوع"></v-autocomplete>
              <v-text-field v-model="addedDate" type="date" label="اختر التاريخ" outlined rounded></v-text-field>

              <v-text-field outlined rounded v-model="number" label="العدد"> </v-text-field>
              <v-file-input multiple label="الملفات" outlined rounded v-model="files" @change="getAllFiles"></v-file-input>
            </v-col>
          </v-row>

          <v-textarea outlined rounded rows="3" row-height="15" name="input-7-4" v-model="description" label="نص الكتاب "></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveAdminOrder"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة امر اداري</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    employeeId: Number,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    datePickerAddModel: false,
    items: [],
    show: false,
    addedDate: "",
    incomeNumber: "",
    overlay: false,
    archiveSubject: "",
    description: "",
    note: "بلا",
    from: "",
    to: "",
    number: "",
    incomeDate: "",
    yearStudyId: "",
    files: [],
    dates: [],
    image: "",
  }),
  created() {
    let x = new Date();
    console.log(x);
  },
  methods: {
    getAllFiles() {
      let self = this;
      if (this.files.length > 0) {
        self.overlay = true;
        let formData = new FormData();
        formData.append("attachment", self.files[0]);

        self.$http.post(`${self.$store.state.apiUrlEmployee}/uploadAppImage`, formData).then((res) => {
          self.overlay = false;
          self.image = res.data.imagePath;
          console.log(res);
        });
      }
    },
    getDates() {
      this.datePickerSearchModel = false;
      this.datePickerAddModel = false;
    },
    saveAdminOrder() {
      let self = this;
      if (self.addedDate && self.archiveSubject && self.description && self.number) {
        let adminOrder = {
          date: `${new Date(self.addedDate).getFullYear()}-${new Date(self.addedDate).getMonth() + 1}-${new Date(self.addedDate).getDate()}`,
          subjectId: self.archiveSubject,
          number: self.number,
          description: self.description,
          createdBy: self.$store.state.userData.idEmployee,
          employeeId: self.employeeId,
          image: self.image,
        };
        self.$http
          .post(`${self.$store.state.apiUrlEmployee}/addEmployeeAdminOrder`, adminOrder, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            console.log(res.data);
            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
            console.log(res);
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
