<template>
  <v-app>
    <v-app-bar app color="primary" v-if="$store.state.user != null">
      <div class="d-flex align-center" style="width: 100%">
        <v-row>
          <v-col cols="4"><img :src="require('./assets/logo3.png')" width="70" /></v-col>
          <!-- <v-col cols="8"><h3 style="color: #d6e0f0; margin-top: 10%">الارشفة الالكترونية</h3></v-col> -->
        </v-row>
        <v-spacer></v-spacer>
        <div>
          <v-list class="justify-space-around d-flex" dense color="primary">
            <v-list-item-group v-model="selectedItem" class="d-flex">
              <v-list-item v-for="(item, i) in items" :key="i" style="margin: 0 10px" color="white" @click="page(item)">
                <v-list-item-icon>
                  <v-icon color="white">{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title style="color: #ffffff">{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>

        <v-spacer></v-spacer>
        <div>
          <v-menu rounded="lg" offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-btn elevation="0" color="primary" class="white--text" v-bind="attrs" v-on="on">
                <v-icon left color="white"> mdi-account-circle </v-icon>

                <span style="color: #d6e0f0; font-size: 12px">
                  {{ $store.state.userData.mail }}
                </span>
              </v-btn>
            </template>

            <v-list style="padding: 0px">
              <v-list-item link @click="logout">
                <v-list-item-title style="color: #006d77; font-size: 12px">
                  <v-icon left color="primary"> mdi-location-exit </v-icon>
                  تسجيل خروج
                </v-list-item-title>
              </v-list-item>

              <v-list-item link @click="$router.push('/settings').catch((e) => {})">
                <v-list-item-title style="color: #006d77; font-size: 12px">
                  <v-icon left color="primary"> mdi-cogs </v-icon>
                  الاعدادات
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div style="margin-top: 50px">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
const geolocation = require("geolocation");
export default {
  name: "App",

  data: () => ({
    selectedItem: 0,
    items: [
      { title: "/", text: "الرئيسية", icon: "mdi-home" },
      {
        title: "Outer",
        text: "الكتب الصادرة",
        icon: "mdi-archive-arrow-up",
      },
      {
        title: "Inner",
        text: "الكتب الواردة",
        icon: "mdi-archive-arrow-down",
      },
      {
        title: "Inside",
        text: "المذكرات الداخلية",
        icon: "mdi-archive-refresh",
      },
      {
        title: "old",
        text: "الصادر والوارد القديم",
        icon: "mdi-archive-arrow-down",
      },
      { title: "Employee", text: "الموظفين", icon: "mdi-account-group" },
      { title: "notify-section", text: "اشعارات الاقسام", icon: "mdi-bell" },
      { title: "Subjects", text: "المواضيع", icon: "mdi-post" },
    ],
  }),

  created() {
    let self = this;

    // geolocation.getCurrentPosition(function (err, position) {
    //    if (err) throw err;
    //    console.log(position);
    // });

    this.$store
      .dispatch("getUserDate")
      .then(() => {
        if (this.$store.state.user) {
          this.$router.push("/").catch(() => {});
          if (this.$store.state.userData.sectionId != 33) {
            this.items = [
              { title: "/", text: "الرئيسية", icon: "mdi-home" },
              {
                title: "Inside",
                text: "المذكرات الداخلية",
                icon: "mdi-archive-refresh",
              },
            ];
          }

          console.log(this.$store.state.userData);
          this.$http
            .get(`${this.$store.state.apiUrlEmployee}/certificates`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            })
            .then((res) => {
              this.$store.state.certificates = res.data;
            });

          this.$http
            .get(`${this.$store.state.apiUrlEmployee}/academicTitles`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            })
            .then((res) => {
              this.$store.state.academicTitles = res.data;
            });

          this.$http
            .get(`${this.$store.state.apiUrlEmployee}/roles`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            })
            .then((res) => {
              this.$store.state.roles = res.data;
            });

          this.$http
            .get(`${this.$store.state.apiUrlEmployee}/provinces`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            })
            .then((res) => {
              this.$store.state.provinces = res.data;
            });

          this.$http
            .get(`${this.$store.state.apiUrlEmployee}/contractTypes`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            })
            .then((res) => {
              this.$store.state.contractTypes = res.data;
            });
        } else {
          this.$router.push("/login").catch((e) => {});
        }
      })
      .catch(() => {});

    let selectedYear = localStorage.getItem("selectedYear");

    if (selectedYear) {
      this.$store.state.selectedYear = selectedYear;
    } else {
      localStorage.setItem("selectedYear", 73);
      this.$store.state.selectedYear = 73;
    }

    this.$http.get(`${this.$store.state.apiUrl}/archiveSubjects`).then((res) => {
      this.$store.state.subjects = res.data;
    });
    this.$http
      .get(`${this.$store.state.apiUrlRegister}/allSections`, {
        headers: { Authorization: `Bearer ${self.$store.state.user}` },
      })
      .then((res) => {
        console.log(res.data);
        this.$store.state.sections = res.data;
      });
    self.$http.get(`${self.$store.state.apiUrl}/archivesCount`).then((res) => {
      console.log(res.data);
      let counters = {
        outer: res.data.filter((archive) => {
          if (archive.archiveTypeId == 1) {
            return archive;
          }
        })[0]?.archiveCount
          ? res.data.filter((archive) => {
              if (archive.archiveTypeId == 1) {
                return archive;
              }
            })[0].archiveCount
          : 0,
        inner: res.data.filter((archive) => {
          if (archive.archiveTypeId == 2) {
            return archive;
          }
        })[0]?.archiveCount
          ? res.data.filter((archive) => {
              if (archive.archiveTypeId == 2) {
                return archive;
              }
            })[0].archiveCount
          : 0,
        inside: res.data.filter((archive) => {
          if (archive.archiveTypeId == 3) {
            return archive;
          }
        })[0]?.archiveCount
          ? res.data.filter((archive) => {
              if (archive.archiveTypeId == 3) {
                return archive;
              }
            })[0].archiveCount
          : 0,
        order: 0,
      };

      self.$store.state.counts = counters;
    });
  },
  methods: {
    initMap() {
      let location;
      if ("geolocation" in navigator) {
        console.log("Location services available");
        navigator.geolocation.getCurrentPosition(function (position) {
          console.log(position);
          location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          console.log(location);
          let map = new google.maps.Map(document.getElementById("map"), {
            center: location,
            scrollwheel: false,
            zoom: 7,
          });
        });
      } else {
        document.getElementById("status").innerHTML = "Location Services not available!";
        return 0;
      }
    },
    page(item) {
      this.$router.push(item.title).catch(() => {});
    },
    logout() {
      localStorage.removeItem("archive");
      location.reload();
    },
  },
};
</script>
<style>
@import url("./font.css");

body {
  background-color: #e4e4e4 !important;
}
.side-bar {
  color: red;
}
.warning-color,
.table-warning-color {
  color: #006d77 !important;
}
.table-warning-color {
  background-color: #ffffff !important;
}
.table-warning-color tr th {
  color: #006d77 !important;
}
</style>
