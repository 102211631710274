<template>
   <div>
      <v-dialog v-model="addDialog" persistent max-width="600px" width="600px">
         <v-card>
            <v-toolbar dark color="primary">
               <v-btn icon dark @click="addDialog = false">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
               <v-toolbar-title>اضافة الموضوع</v-toolbar-title>
               <v-spacer></v-spacer>
            </v-toolbar>

            <v-card-text>
               <div style="margin-top: 10px"></div>
               <v-row>
                  <v-col cols="12" sm="6">
                     <v-text-field
                        solo
                        label="اسم الموضوع"
                        v-model="subjectName"
                     ></v-text-field>
                  </v-col>
               </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
               <v-btn color="primary" text @click="addSubject">
                  اضافة موضوع
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
      <v-tooltip bottom>
         <template v-slot:activator="{ on, attrs }">
            <v-btn
               class="mx-2"
               fab
               small
               color="primary"
               @click="addDialog = true"
               v-bind="attrs"
               v-on="on"
            >
               <v-icon dark> mdi-plus </v-icon>
            </v-btn>
         </template>
         <span>اضافة موضوع</span>
      </v-tooltip>
   </div>
</template>

<script>
export default {
   data: () => ({
      addDialog: false,
      subjectName: "",
   }),
   methods: {
      addSubject() {
         if (this.subjectName) {
            this.$http
               .post(`${this.$store.state.apiUrl}/addArchiveSubject`, {
                  subjectName: this.subjectName,
               })
               .then((response) => {
                  console.log(response.data);
                  this.$toasted.success("تم حفظ البيانات");
                  this.addDialog = false;
                  this.subjectName = "";
                  this.$emit("reloadTask");
               })
               .catch((e) => {
                  this.$toasted.error("حدث خطأ في حفظ البيانات");
               });
         } else {
            this.$toasted.error("الرجاء ادخال اسم الموضوع");
         }
      },
   },
};
</script>

<style lang="scss" scoped></style>
