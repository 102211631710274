<template>
  <div>
    <v-dialog v-model="addDialog" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="addDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة اشعار للقسم</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-text-field rounded outlined label="العنوان" v-model="title"></v-text-field>
              <v-autocomplete rounded v-model="sectionId" :items="$store.state.sections" item-text="sectionName" item-value="idSection" outlined label="القسم"></v-autocomplete>
              <v-textarea rounded outlined rows="5" row-height="15" v-model="description" name="input-7-4" label="نص الاشعار"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="AddSectionNotification"> اضافة </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="addDialog = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة موظف</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data: () => ({
    sectionId: "",
    title: "",
    description: "",
    showDate: false,
    showDob: false,
    addDialog: false,
  }),
  methods: {
    AddSectionNotification() {
      let self = this;
      let data = {
        sectionId: self.sectionId,
        title: self.title,
        description: self.description,
        createdBy: self.$store.state.userData.idEmployee,
      };
      if (self.sectionId && self.title && self.description) {
        this.$http
          .post(`${this.$store.state.apiUrlEmployee}/AddSectionNotification`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((response) => {
            console.log(response.data);
            this.$toasted.success("تم حفظ البيانات");
            this.addDialog = false;
            this.$emit("reloadTask");
          })
          .catch((e) => {
            this.$toasted.error("حدث خطأ في حفظ البيانات");
          });
      } else {
        this.$toasted.error("الرجاء ادخال اسم الموضوع");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
