<template>
  <div>
    <v-container class="container sticky-header" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="3">
            <!-- <div class="profile-div"></div> -->
            <div v-if="employeeDetails.profileImage != null" style="text-align: center">
              <img :src="`${$store.state.apiUrlEmployee}/${employeeDetails.profileImage}`" alt="John" width="300" height="300" style="margin: 10px auto; border-radius: 150px" />
            </div>
            <div v-else class="profile-div"></div>
            <div style="padding: 0px 20px">
              <v-text-field outlined :disabled="!update" v-model="$store.state.employee.employeeName" label="الاسم"></v-text-field>

              <v-autocomplete
                v-model="$store.state.employee.sectionId"
                :disabled="!update"
                :items="$store.state.sections"
                item-text="sectionName"
                item-value="idSection"
                outlined
                label="القسم"
              ></v-autocomplete>

              <v-text-field outlined :disabled="!update" v-model="$store.state.employee.phone" label="رقم الهاتف"></v-text-field>
              <v-autocomplete v-model="$store.state.employee.gender" :disabled="!update" :items="$store.state.genders" item-text="name" item-value="name" outlined label="الجنس"></v-autocomplete>
              <v-text-field :disabled="!update" v-model="$store.state.employee.inputDate" label="تاريخ الميلاد" outlined type="date"></v-text-field>
              <v-text-field outlined :disabled="!update" v-model="$store.state.employee.mail" label="البريد"></v-text-field>
              <v-autocomplete v-model="$store.state.employee.roleId" :disabled="!update" :items="$store.state.roles" item-text="roleName" item-value="idRole" outlined label="الوظيفة"></v-autocomplete>
              <v-text-field outlined :disabled="!update" v-model="$store.state.employee.nationality" label="الجنسية"></v-text-field>
              <v-text-field outlined :disabled="!update" v-model="$store.state.employee.motherName" label="اسم الام"></v-text-field>
            </div>
            <v-btn class="ma-2" color="success" outlined @click="updateEmployeeBasicInformation" v-show="update">
              <v-icon left> mdi-pencil </v-icon>
              تعديل المعلومات الشخصية
            </v-btn>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="9">
            <div style="height: 20px"></div>
            <v-alert border="left" color="label"> <h3>المعلومات الاكاديمية</h3> </v-alert>
            <div style="height: 20px"></div>

            <v-row>
              <v-col cols="6" style="padding: 10px">
                <v-autocomplete
                  v-model="employeeDetails.certificateId"
                  :disabled="!update"
                  :items="$store.state.certificates"
                  item-text="certificateName"
                  item-value="idCertificate"
                  outlined
                  label="نوع الشهادة"
                ></v-autocomplete>

                <v-autocomplete
                  v-model="employeeDetails.academicTitleId"
                  :disabled="!update"
                  :items="$store.state.academicTitles"
                  item-text="title"
                  item-value="idAcademicTitle"
                  outlined
                  label="العنوان الوظيفي"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" style="padding: 10px">
                <v-text-field outlined :disabled="!update" v-model="employeeDetails.certificateCountry" label="بلد اصدار الشهادة"></v-text-field>
                <v-text-field outlined :disabled="!update" v-model="employeeDetails.specialist" label="التخصص"></v-text-field>
              </v-col>
            </v-row>

            <div style="height: 20px"></div>
            <v-alert border="left" color="label"> <h3>معلومات السكن</h3> </v-alert>
            <div style="height: 20px"></div>
            <v-row>
              <v-col cols="6" style="padding: 10px">
                <v-autocomplete
                  v-model="employeeDetails.provinceId"
                  :disabled="!update"
                  :items="$store.state.provinces"
                  item-text="provinceName"
                  item-value="idProvince"
                  outlined
                  label="المحافظة"
                ></v-autocomplete>

                <v-text-field outlined :disabled="!update" v-model="employeeDetails.alley" label="المحلة"></v-text-field>
                <v-text-field outlined :disabled="!update" v-model="employeeDetails.houseNumber" label="رقم الدار"></v-text-field>
              </v-col>
              <v-col cols="6" style="padding: 10px">
                <v-text-field outlined :disabled="!update" v-model="employeeDetails.city" label="المنطقة"></v-text-field>
                <v-text-field outlined :disabled="!update" v-model="employeeDetails.avenue" label="الزقاق"></v-text-field>
              </v-col>
            </v-row>

            <div style="height: 20px"></div>
            <v-alert border="left" color="label"> <h3>معلومات اضافية</h3> </v-alert>
            <div style="height: 20px"></div>
            <v-row>
              <v-col cols="6" style="padding: 10px">
                <v-text-field outlined :disabled="!update" v-model="employeeDetails.cardNumber" label="رقم البطاقة"></v-text-field>
                <v-text-field outlined :disabled="!update" v-model="employeeDetails.studyType" label="وقت العمل"></v-text-field>
              </v-col>
              <v-col cols="6" style="padding: 10px">
                <v-text-field outlined :disabled="!update" v-model="employeeDetails.passportNumber" label="رقم الجواز"></v-text-field>
                <v-autocomplete
                  v-model="employeeDetails.idContactType"
                  :disabled="!update"
                  :items="$store.state.contractTypes"
                  item-text="typeName"
                  item-value="idContactType"
                  outlined
                  label="نوع العقد"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-textarea :disabled="!update" rows="3" row-height="15" outlined v-model="employeeDetails.notes" name="input-7-4" label="الملاحظات"></v-textarea>

            <v-btn class="ma-2" color="warning" outlined @click="updateEmployee" v-if="!update">
              <v-icon left> mdi-pencil </v-icon>
              تعديل المعلومات
            </v-btn>

            <v-btn class="ma-2" color="success" outlined @click="updateEmployeeInformation" v-if="update">
              <v-icon left> mdi-pencil </v-icon>
              تعديل
            </v-btn>

            <v-btn class="ma-2" color="error" outlined @click="removeUpdate" v-if="update">
              <v-icon left> mdi-close </v-icon>
              الغاء التعديل
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    employeeDetails: Object,
  },
  data: () => ({
    update: false,
  }),
  created() {
    console.log(this.employeeDetails);
  },
  methods: {
    updateEmployee() {
      let self = this;
      self.update = true;
    },
    removeUpdate() {
      let self = this;
      self.update = false;
    },
    updateEmployeeBasicInformation() {
      let self = this;
      self.overlay = true;
      let contract = new Date(self.$store.state.employee.contractDate);

      self.$store.state.employee.contractDate = `${contract.getFullYear()}-${contract.getMonth() + 1}-${contract.getDate()}`;
      self.$store.state.employee.dob = self.$store.state.employee.dobFormat;

      let employeeData = {
        employeeName: self.$store.state.employee.employeeName,
        sectionId: self.$store.state.employee.sectionId,
        gender: self.$store.state.employee.gender,
        nationality: self.$store.state.employee.nationality,
        password: self.$store.state.employee.password,
        contractDate: self.$store.state.employee.contractDate,
        contractTypeId: self.$store.state.employee.contractTypeId,
        phone: self.$store.state.employee.phone,
        mail: self.$store.state.employee.mail,
        dob: self.$store.state.employee.inputDate,
        statusId: self.$store.state.employee.statusId,
        motherName: self.$store.state.employee.motherName,
        roleId: self.$store.state.employee.roleId,
      };

      console.log(employeeData);

      self.$http
        .put(`${self.$store.state.apiUrlEmployee}/employee/${self.$store.state.employee.idEmployee}`, employeeData, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((resImageUpdate) => {
          self.overlay = false;
          this.$toasted.success("تم رفع خزن البيانات");
        })
        .catch((error) => {
          self.overlay = false;
          console.log(error);
          this.$toasted.error("حدث خطأ في رفع الصورة");
        });
    },

    updateEmployeeInformation() {
      let self = this;
      self.overlay = true;
      if (self.employeeDetails?.idEmployeeInformation) {
        self.$http
          .put(`${self.$store.state.apiUrlEmployee}/employeeInformation/${self.employeeDetails.idEmployeeInformation}`, self.employeeDetails, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((resImageUpdate) => {
            self.overlay = false;
            console.log(resImageUpdate);
            this.$toasted.success("تم رفع خزن البيانات");
          })
          .catch((error) => {
            self.overlay = false;
            console.log(error);
            this.$toasted.error("حدث خطأ في رفع الصورة");
          });
      } else {
        let data = {
          academicTitleId: self.employeeDetails.academicTitleId,
          certificateCountry: self.employeeDetails.certificateCountry,
          certificateId: self.employeeDetails.certificateId,
          specialist: self.employeeDetails.specialist,
          provinceId: self.employeeDetails.provinceId,
          city: self.employeeDetails.city,
          avenue: self.employeeDetails.avenue,
          alley: self.employeeDetails.alley,
          houseNumber: self.employeeDetails.houseNumber,
          notes: self.employeeDetails.notes,
          cardNumber: self.employeeDetails.cardNumber,
          martialStatus: 1,
          passportNumber: self.employeeDetails.passportNumber,
          studyType: self.employeeDetails.studyType,
          isRetired: "كلا",
          employeeId: self.employeeDetails.idEmployee,
        };
        self.$http
          .post(`${self.$store.state.apiUrlEmployee}/addEmployeeInformation`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((resImageUpdate) => {
            self.overlay = false;
            console.log(resImageUpdate);
            self.initialData();
            this.$toasted.success("تم رفع خزن البيانات");
          })
          .catch((error) => {
            self.overlay = false;
            console.log(error);
            this.$toasted.error("حدث خطأ في رفع البيانات");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-div {
  width: 300px;
  height: 300px;
  background: rgb(121, 121, 121);
  margin: 10px auto;
  border-radius: 150px;
}
</style>
