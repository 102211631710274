<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <div v-if="employeeDetails != null">
      <v-container class="container sticky-header" fluid>
        <v-card elevation="6">
          <v-row style="margin: 10px">
            <v-col cols="10">
              <h3 class="warning-color">تفاصيل الموظف : {{ $store.state.employee.employeeName }}</h3>
            </v-col>
            <v-col cols="2" align="left">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-2" fab small color="primary" @click="$router.go(-1)" v-bind="attrs" v-on="on">
                    <v-icon dark> mdi-arrow-left </v-icon>
                  </v-btn>
                </template>
                <span>رجوع</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
      <v-container fluid>
        <v-tabs v-model="tabs" center-active color="primary" grow centered right>
          <v-tab>الملف الشخصي</v-tab>
          <v-tab>الاوامر الادارية</v-tab>
          <v-tab>اشعارات الموظف</v-tab>

          <v-tabs-items v-model="tabs" class="pa-5 border-top">
            <v-tab-item>
              <EmployeeProfile :employeeDetails="employeeDetails" />
            </v-tab-item>

            <v-tab-item>
              <EmployeeAdminOrder :employeeId="$store.state.employee.idEmployee" />
            </v-tab-item>

            <v-tab-item>
              <PersonalNotification :employeeId="$store.state.employee.idEmployee" />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-container>
    </div>
  </div>
</template>
<script>
import EmployeeProfile from "./../components/employeeDetails/EmployeeProfile.vue";
import EmployeeAdminOrder from "./../components/employeeDetails/EmployeeAdminOrder.vue";
import PersonalNotification from "./../components/employeeDetails/PersonalNotification.vue";
export default {
  components: {
    EmployeeProfile,
    EmployeeAdminOrder,
    PersonalNotification,
  },
  data: () => ({
    overlay: false,
    employeeDetails: null,
    tabs: null,
  }),
  created() {
    let self = this;
    self.overlay = true;
    self.$http
      .get(`${self.$store.state.apiUrlEmployee}/employee/${self.$store.state.employee.idEmployee}`, {
        headers: { Authorization: `Bearer ${self.$store.state.user}` },
      })
      .then((res) => {
        console.log(res.data);
        self.overlay = false;
        self.employeeDetails = res.data;
      });
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
