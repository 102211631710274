<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container" fluid>
      <v-card color="accent" elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3 class="warning-color">صفحة الموظفين</h3>
          </v-col>

          <v-col cols="2" style="text-align: left">
            <AddEmployee style="display: inline-block" :sections="sections" :roles="roles" :contractTypes="contractTypes" @reloadTask="initialData" />
            <!-- <SearchOld style="display: inline-block" @clicked="onSearch" /> -->
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="container" fluid>
      <v-card color="accent" elevation="6">
        <v-card-title class="warning-color">
          الموظفين
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="بحث" single-line hide-details solo></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
          <template v-slot:[`item.isActive`]="{ item }">
            <v-avatar size="20" color="success" v-if="item.isActive == 1"> </v-avatar>

            <v-avatar size="20" color="error" v-else> </v-avatar>
          </template>

          <template v-slot:[`item.employeeName`]="{ item }">
            <h4 v-if="item.statusId == 1" style="padding: 0 3px">{{ item.employeeName }}</h4>
            <h4 v-else style="padding: 0 3px; color: red">{{ item.employeeName }}</h4>
          </template>
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <v-avatar size="32" v-if="item.image != null">
              <img :src="item.image" alt="John" />
            </v-avatar>

            <v-avatar size="32" color="indigo" v-else>
              <v-icon dark> mdi-account-circle </v-icon>
            </v-avatar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <!-- <EditSection
                     style="display: inline-block"
                     :item="item"
                     @reloadTask="initialData()"
                  /> -->

            <v-btn icon @click="dialog = false">
              <v-icon @click="updateEmployeeStatus(item)" :color="`${item.statusId == 1 ? 'error' : 'success'}`"> {{ item.statusId == 1 ? "mdi-close-circle" : "mdi-check-circle" }} </v-icon>
            </v-btn>

            <v-btn icon @click="dialog = false">
              <v-icon @click="goToEmployeeDetails(item)" color="primary"> mdi-arrow-left-circle </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import SearchOld from "../components/oldArchive/SearchOld.vue";
import DeleteOuter from "../components/outer/DeleteOuter.vue";
import AddEmployee from "../components/employee/AddEmployee.vue";
import Images from "../components/images.vue";
export default {
  components: {
    SearchOld,
    Images,
    DeleteOuter,
    AddEmployee,
  },
  data: () => ({
    items: [],
    search: "",
    overlay: false,
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "الاسم", value: "employeeName" },
      { text: "القسم", value: "sectionName" },
      {
        text: "البريد الالكتروني",
        sortable: false,
        value: "mail",
      },
      {
        text: "الجنس",
        sortable: false,
        value: "gender",
      },
      {
        text: "الوظيفة",
        sortable: false,
        value: "roleName",
      },
      { text: "الاجراءات", value: "actions" },
    ],

    roles: [],
    contractTypes: [],
    sections: [],
  }),
  created() {
    this.initialData();
    console.log(this.$store.state.user);
  },
  methods: {
    onSearch(value) {
      console.log(value);
      this.items = value;
    },

    initialData() {
      let self = this;
      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlEmployee}/employees`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlEmployee}/contractTypes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlEmployee}/roles`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlRegister}/allSections`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((employees, contractTypes, roles, sections) => {
            console.log(employees);
            self.items = employees.data;
            self.roles = roles.data;
            self.contractTypes = contractTypes.data;
            self.sections = sections.data;
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
    updateEmployeeStatus(emp) {
      let self = this;
      let statusId = emp.statusId == 1 ? 2 : 1;
      self.$http
        .put(
          `${self.$store.state.apiUrlEmployee}/employeeStatus/${emp.idEmployee}`,
          { statusId: statusId },
          {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }
        )
        .then((res) => {
          self.initialData();
        })
        .catch((error) => {
          this.$toasted.error("حدث خطأ في تعديل البيانات");
        });
    },
    goToEmployeeDetails(employee) {
      const self = this;
      self.$store.state.employee = employee;
      self.$router.push("/employee-details");
      console.log(employee);
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.items.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.employeeName.toLowerCase().includes(v));
        });
      } else {
        return this.items;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
