<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container class="container" fluid>
      <v-card color="accent" elevation="6">
        <v-card-title class="warning-color">
          الاوامر الادارية
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="بحث" single-line hide-details outlined></v-text-field>
          <div style="width: 30px"></div>
          <AdminOrder :employeeId="employeeId" @reloadTask="initialData" />
        </v-card-title>
        <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
          <template v-slot:[`item.isActive`]="{ item }">
            <v-avatar size="20" color="success" v-if="item.isActive == 1"> </v-avatar>

            <v-avatar size="20" color="error" v-else> </v-avatar>
          </template>

          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <Images :images="[item.image]" style="display: inline-block" />

            <v-btn icon @click="dialog = false" v-if="$store.state.userData.roleId != 31">
              <DeleteAdminOrder :dataToDelete="item" @reloadTask="initialData()" />
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AdminOrder from "./AddAdminOrder.vue";
import DeleteAdminOrder from "./DeleteAdminOrder.vue";
import Images from "./EmployeeImage.vue";
export default {
  components: {
    AdminOrder,
    DeleteAdminOrder,
    Images,
  },
  props: {
    employeeId: Number,
  },
  data: () => ({
    items: [],
    search: "",
    overlay: false,
    headers: [
      { text: "العدد", value: "idSectionNotification" },
      { text: "الموضوع", value: "subjectName" },
      { text: "تاريخ الامر", value: "dateFormat" },
      {
        text: "العدد",
        value: "number",
      },
      {
        text: "نص الامر",
        value: "description",
      },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.overlay = true;
      self.$http
        .get(`${self.$store.state.apiUrlEmployee}/employeeAdminOrdersId/${self.employeeId}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.items = res.data;
          self.overlay = false;
        });
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.items.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.title.toLowerCase().includes(v));
        });
      } else {
        return this.items;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
