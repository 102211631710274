<template>
  <div>
    <v-dialog v-model="addDialog" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="addDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة موظف</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-text-field rounded outlined label="اسم الموظف" v-model="employeeName"></v-text-field>

              <v-autocomplete rounded v-model="sectionId" :items="sections" item-text="sectionName" item-value="idSection" outlined label="القسم"></v-autocomplete>

              <v-autocomplete rounded v-model="gender" :items="$store.state.genders" item-text="name" item-value="name" outlined label="الجنس"></v-autocomplete>

              <v-text-field rounded outlined label="الجنسية" v-model="nationality"></v-text-field>

              <v-dialog ref="dialogDate" v-model="showDate" :return-value.sync="contractDate" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined rounded v-model="contractDate" label="تاريخ العقد" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="contractDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.dialogDate.save(contractDate)"> موافق </v-btn>
                  <v-btn text color="primary" @click="showDate = false"> الغاء </v-btn>
                </v-date-picker>
              </v-dialog>

              <v-autocomplete rounded v-model="contractTypeId" :items="contractTypes" item-text="typeName" item-value="idContactType" outlined label="نوع العقد"></v-autocomplete>

              <v-text-field rounded outlined label="رقم الهاتف" v-model="phone"></v-text-field>

              <v-text-field rounded outlined label="البريد الجامعي" v-model="mail"></v-text-field>

              <v-dialog ref="dialog" v-model="showDob" :return-value.sync="dob" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined rounded v-model="dob" label="تاريخ الميلاد" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="dob" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.dialog.save(dob)"> موافق </v-btn>
                  <v-btn text color="primary" @click="showDob = false"> الغاء </v-btn>
                </v-date-picker>
              </v-dialog>
              <v-text-field rounded outlined label="اسم الام" v-model="motherName"></v-text-field>

              <v-autocomplete rounded v-model="roleId" :items="roles" item-text="roleName" item-value="idRole" outlined label="الصلاحية"></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="addEmployee"> اضافة </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="addDialog = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة موظف</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    roles: Array,
    contractTypes: Array,
    sections: Array,
  },
  data: () => ({
    addDialog: false,
    employeeName: "",
    sectionId: "",
    gender: "",
    nationality: "",
    password: "",
    contractDate: "",
    contractTypeId: "",
    phone: "",
    mail: "",
    dob: "",
    motherName: "",
    roleId: "",
    showDate: false,
    showDob: false,
  }),
  methods: {
    addEmployee() {
      let self = this;
      let data = {
        employeeName: self.employeeName,
        sectionId: self.sectionId,
        gender: self.gender,
        nationality: self.nationality,
        password: "$2b$10$rz6Om2l3TfZG7JvHPacKH.Wpg1LO1nG4Q31dpKqd17/Q4iEe1N0RG",
        contractDate: self.contractDate,
        contractTypeId: self.contractTypeId,

        phone: self.phone,
        mail: self.mail,
        dob: self.dob,
        statusId: 1,
        motherName: self.motherName,
        roleId: self.roleId,
      };
      if (this.employeeName && self.sectionId && self.gender && self.nationality && this.contractDate && self.contractTypeId && self.phone && self.mail && self.motherName && self.roleId) {
        this.$http
          .post(`${this.$store.state.apiUrlEmployee}/addEmployee`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((response) => {
            console.log(response.data);
            this.$toasted.success("تم حفظ البيانات");
            this.addDialog = false;
            this.employeeName = "";
            this.$emit("reloadTask");
          })
          .catch((e) => {
            this.$toasted.error("حدث خطأ في حفظ البيانات");
          });
      } else {
        this.$toasted.error("الرجاء ادخال اسم الموضوع");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
