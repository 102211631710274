<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة كتاب الوارد</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="6">
              <v-text-field v-if="$store.state.userData.sectionId != 2" solo v-model="from" label="من"></v-text-field>
              <v-text-field v-if="$store.state.userData.sectionId == 2" solo v-model="from" label="جهة الاصدار"></v-text-field>
              <v-autocomplete v-model="archiveSubject" :items="$store.state.subjects" item-text="subjectName" item-value="idArchiveSubject" dense solo filled label="الموضوع"></v-autocomplete>
              <v-dialog ref="dialog" v-model="datePickerAddModel" persistent width="290px">
                <template v-slot:activator="{}">
                  <v-text-field v-if="$store.state.userData.sectionId != 2" v-model="addedDate" type="date" label="اختر التاريخ" solo></v-text-field>

                  <label>
                    تاريخ الكتاب
                    <v-text-field v-if="$store.state.userData.sectionId == 2" v-model="addedDate" label="تاريخ صادرة الكتاب الوارد" solo type="date"></v-text-field>
                  </label>
                </template>
                <v-date-picker v-model="addedDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="datePickerAddModel = false"> الغاء </v-btn>
                  <v-btn text color="primary" @click="getDates"> موافق </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="6">
              <v-text-field solo v-model="to" label="الى" v-if="$store.state.userData.sectionId != 2"></v-text-field>

              <v-text-field solo v-model="archiveNumber" label="رقم صادرة الكتاب الوارد" v-if="$store.state.userData.sectionId == 2"></v-text-field>

              <v-text-field solo v-model="archiveNumber" label="العدد" v-if="$store.state.userData.sectionId != 2"></v-text-field>
              <v-file-input multiple label="الملفات" solo dense v-model="files" @change="getAllFiles"></v-file-input>
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col cols="6">
              <label v-if="$store.state.userData.sectionId != 2">
                عدد الوارد
                <v-text-field solo v-model="incomeNumber" label="عدد الوارد"></v-text-field>
              </label>
            </v-col>
            <v-col cols="6">
              <label v-if="$store.state.userData.sectionId != 2">
                تاريخ الوارد
                <v-text-field v-model="incomeDate" type="date" label="اختر التاريخ" solo></v-text-field>
              </label>
            </v-col>
          </v-row> -->

          <v-textarea solo rows="3" row-height="15" name="input-7-4" v-model="archiveDescription" label="نص الكتاب الواردة"></v-textarea>

          <v-textarea v-if="$store.state.userData.sectionId != 2" rows="3" row-height="15" solo v-model="note" name="input-7-4" label="الملاحظات"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveOuterData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة كتاب وارد</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    datePickerAddModel: false,
    items: [],
    show: false,
    addedDate: "",
    incomeNumber: "",
    overlay: false,
    archiveSubject: "",
    archiveDescription: "",
    note: "بلا",
    from: "",
    to: "",
    archiveNumber: "",
    incomeDate: "",
    yearStudyId: "",
    files: [],
    dates: [],
    archiveImages: [],
  }),
  created() {
    let x = new Date();
    console.log(x);
  },
  methods: {
    getAllFiles() {
      let self = this;
      if (this.files.length > 0) {
        self.overlay = true;
        let formData = new FormData();
        self.files.forEach((file, index) => {
          console.log(index);
          formData.append("files", file);
        });
        self.$http.post(`${self.$store.state.apiUrl}/uploadArchive`, formData).then((res) => {
          self.overlay = false;
          self.archiveImages = res.data;
          console.log(res);
        });
      }
    },
    getDates() {
      this.datePickerSearchModel = false;
      this.datePickerAddModel = false;
    },
    saveOuterData() {
      let self = this;
      if (self.$store.state.userData.sectionId != 2 && self.addedDate && self.archiveSubject && self.note && self.archiveDescription && self.from && self.to && self.archiveNumber) {
        let innerData = {
          from: self.from,
          to: self.to,
          archiveDate: `${new Date(self.addedDate).getFullYear()}-${new Date(self.addedDate).getMonth() + 1}-${new Date(self.addedDate).getDate()}`,
          archiveSubjectId: self.archiveSubject,
          archiveNumber: self.archiveNumber,
          subjectDescription: self.archiveDescription,
          note: self.note,
          sectionId: self.$store.state.userData.sectionId,
          archiveTypeId: 2,
          yearStudyId: 73,
          incomeDate: "2023-01-01",
          incomeNumber: "0",
        };

        self.$http.post(`${self.$store.state.apiUrl}/addArchive`, innerData).then((res) => {
          console.log(res.data);
          if (self.archiveImages.length > 0) {
            console.log(self.archiveImages);
            let images = self.archiveImages.map((image) => {
              return {
                imagePath: image.imagePath,
                archiveId: res.data.id,
              };
            });
            self.$http.post(`${self.$store.state.apiUrl}/addArchiveImages`, images).then((resImages) => {
              self.snackbar = true;
              self.text = "تم اضافة البيانات";
              console.log(resImages);
            });
          } else {
            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
            console.log(res);
          }
        });
      } else if (self.$store.state.userData.sectionId == 2 && self.addedDate && self.archiveSubject && self.from && self.archiveNumber) {
        self.addedDate = `${new Date(self.addedDate).getFullYear()}-${new Date(self.addedDate).getMonth() + 1}-${new Date(self.addedDate).getDate()}`;

        let innerData = {
          from: self.from,
          to: "الادارية",
          archiveDate: self.addedDate,
          archiveSubjectId: self.archiveSubject,
          archiveNumber: self.archiveNumber,
          subjectDescription: self.archiveDescription,
          note: "لايوجد",
          sectionId: self.$store.state.userData.sectionId,
          archiveTypeId: 2,
          yearStudyId: 73,
          incomeDate: self.incomeDate,
          incomeNumber: "0",
        };
        self.$http.post(`${self.$store.state.apiUrl}/addArchive`, innerData).then((res) => {
          console.log(res.data);
          if (self.archiveImages.length > 0) {
            console.log(self.archiveImages);
            let images = self.archiveImages.map((image) => {
              return {
                imagePath: image.imagePath,
                archiveId: res.data.id,
              };
            });
            self.$http.post(`${self.$store.state.apiUrl}/addArchiveImages`, images).then((resImages) => {
              console.log(resImages);
              this.$toasted.success("تم اضافه البيانات");
              this.$emit("reloadTask");
            });
          } else {
            console.log(res.data);
            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          }
        });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
